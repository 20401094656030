import { ClrDatagridStateInterface } from "@clr/angular/data/datagrid/interfaces/state.interface";
import { Animal } from "./animal";
import { Order } from "./order";
import { getFiltersFromDatagrid } from "./paginate";
import { Paginate } from "./paginate";
import { Sample } from "./sample";
import { Service } from "./service";
import { Status } from "./status";
import { UserRole } from "@shared/models/user";

export interface Research {
  id: number
  services?: Service[]
  service: Service
  serviceId?: number
  sample: Sample
  order: Order
  status: Status
  result: string
  deadline?: string
  dateOfGettingResult?: string
  dateOfIssue?: string
  dateOfStartIssue?: string
  createdAt?: string
  mothers?: Animal[] | null
  fathers?: Animal[] | null
  isDeleted: boolean
  subContragentModel: {
    id: number
    name: string
  }
  dateOfInter: string | null
  admissionOfficeDate: string | null
  interDoctor: InterDoctor
  researchAnimalName?: string
  researchHumanName?: string
  currency?: string
  dateOfInvoice?: string
  dateOfShipment?: string
  invoiceNumber?: string
  sumPerSample?: number
  invoiceAmount?: number
  comment?: string
  contractNumber?: string
  contragentName?: string
}

export interface InterDoctor {
  id: string
  userName: string
  firstName: string
  lastName: string
  middleName: string
  roles: UserRole[]
  email: string
}
export interface researchServiceIds {
  researchId: number,
  serviceId: number
}

export interface researchServiceIdsPatch {
  serviceId: number
}

export interface ResearchInput {
  id?: number | null
  serviceId?: number
  serviceIds?: number[] | null
  researchServiceIds?: any
  sampleId?: number
  orderId?: number | null
  statusId: number
  result?: string
  deadline?: string | null
  potentialMothersIds?: number[]
  potentialFathersIds?: number[]
  isDeleted: boolean
  interUserId?: string | null
  dateOfInter?: string | null
  dateOfGettingResult?: string | null
  dateOfIssue?: string | null
  dateOfStartIssue?: string | null
  subcontractorId?: number
  invoiceNumber?: string
  sumPerSample?: number
  invoiceAmount?: number
  contractNumber?: string
  currency?: string
  dateOfShipment?: string | null
  dateOfInvoice?: string | null
}
export interface ChangeStatusInput {
  ids: number[],
  statusId: number
}
export interface ResearchFilters {
  ids?: any
  search?: string
  status?: number
  idOrder?: number
  orderBy?: string
  isDeleted?: boolean
  biomatName?: string
  idOrderType?: number
  serviceName?: string
  sampleNumber?: string
  orderNumber?: string
  createdAtEnd?: string
  createdAtBegin?: string
  typeSubdivisionId?: number
  typeSubdivisionName?: string
  orderCreatedAtBegin?: string
  orderCreatedAtEnd?: string
  deadlineBegin?: string
  deadlineEnd?: string
  dateOfPaymentBegin?: string
  dateOfPaymentEnd?: string
  dateOfInterBegin?: string
  dateOfStartIssueBegin?: string
  dateOfStartIssueEnd?: string
  dateOfInterEnd?: string
  dateOfShipment?: string
  dateOfGettingResultBegin?: string
  dateOfGettingResultEnd?: string
  dateOfIssueBegin?: string
  dateOfIssueEnd?: string
  admissionOfficeDateBegin?: string
  admissionOfficeDateEnd?: string
  admissionLabDateBegin?: string
  admissionLabDateEnd?: string
  contragentId?: string
  serviceIds?: string
  sumMin?: number
  sumMax?: number
  result?: string
  limit?: number
  offset?: number
  biomatId?: number
  animalInventoryNumber?: number
  animalName?: string
}

export interface ResearchesResponse extends Paginate {
  data: Research[]
}

export interface ResearchResults {
  orderNumber: string
  sampleNumber: string
  results: Result[]
}
export interface Result {
  id: number
  orderNumber: string
  result: string
  serviceName: string
  serviceShortName: string
}

export const getFilters = (state: ClrDatagridStateInterface): ResearchFilters => {
  let stageFilters = getFiltersFromDatagrid(state);
  return {
    ...(stageFilters.hasOwnProperty('sample.number') ? {
      sampleNumber: stageFilters['sample.number']
    } : {}),
    ...(stageFilters.hasOwnProperty('service.name') ? {
      serviceName: stageFilters['service.name']
    } : {}),
    ...(stageFilters.hasOwnProperty('sample.biomat.name') ? {
      biomatName: stageFilters['sample.biomat.name']
    } : {}),
    ...(stageFilters.hasOwnProperty('order.number') ? {
      orderNumber: stageFilters['order.number']
    } : {}),
    ...(stageFilters.hasOwnProperty('result') ? {
      result: stageFilters['result']
    } : {}),
    ...(stageFilters.hasOwnProperty('order.typeSubdivision.name') ? {
      typeSubdivisionName: stageFilters['order.typeSubdivision.name']
    } : {})
  }
}

export const defaultResearchStatus: Status = {
  name: "Новое",
  status: "New",
  id: 1
}
