import { CommonModule, registerLocaleData } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { debounceTime, pairwise, startWith, Subject, takeUntil } from "rxjs";
import localeRu from '@angular/common/locales/ru';
import { ClrCheckboxModule, ClrInputModule, ClrIconModule, ClrModalModule } from "@clr/angular";
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";

import { Gender, GENDERS, translateToEngGender, translateToRusGender } from "../../../../../app/constants/gender";

import { ButtonComponent } from "@core/components/button/button.component";
import { SelectComponent } from "@core/components/input/select/select.component";
import { CheckboxComponent } from "@core/components/input/checkbox/checkbox.component";
import { TextFieldComponent } from "@core/components/input/text-field/text-field.component";
import { DatepickerComponent } from "@core/components/input/date-picker/datepicker.component";
import { AutocompleteComponent } from "@core/components/input/autocomplete/autocomplete.component";

import { convertFormat, dateValidator } from "@shared/helpers/date";

import { Status } from "@shared/models/status";
import { Filter } from "@shared/models/filter";
import { OrderType, OrderTypes } from "@shared/models/order";
import { Biomat, BiomatsResponse } from "@shared/models/biomat";
import { defaultAnimalSampleStatus, defaultHumanAndScienceSampleStatus, defaultSampleStatus, Sample, SampleInput } from "@shared/models/sample";
import { Human, HumansResponse, renderHumanName } from "@shared/models/human";
import { Animal, AnimalsResponse, renderParentName } from "@shared/models/animal";
import { Oligonucleotide, OligonucleotidesResponse } from "@shared/models/oligonucleotides";
import { ItemArrType, ItemType } from "@shared/models/addOrUpdate";

import { AnimalsService } from "../../../admin/services/animals.service";
import { DictionariesService } from "../../../admin/services/dictionaries.service";
import { AnimalComponent } from "../../../admin/components/registers/animals/animal/animal.component";

import { HumansService } from "../../../admin/services/humans.service";
import { HumanComponent } from "../../../admin/components/registers/humans/human/human.component";
import { SampleService } from "@modules/samples/services/sample.service";

registerLocaleData(localeRu);

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss'],
  imports: [
    ClrIconModule,
    ClrModalModule,
    ClrInputModule,
    ClrCheckboxModule,

    FormsModule,
    CommonModule,
    ReactiveFormsModule,

    ButtonComponent,
    SelectComponent,
    CheckboxComponent,
    TextFieldComponent,
    DatepickerComponent,
    AutocompleteComponent,

    AnimalComponent,
    HumanComponent
  ],
  standalone: true
})

export class SampleComponent {
  @Input() samples: Sample[];
  @Input() handleClose: () => void;
  @Input() addOrUpdateSample?: (sample: Sample) => void;
  @Input() addOrUpdateItem?: (items: ItemArrType, item: ItemType) => void;

  @Input() isCopy?: boolean;
  @Input() orderName: string;
  @Input() statuses: Status[];
  @Input() orderType: OrderType | null;
  @Input() orderTypes?: OrderType[];
  @Input() currentNumber: number;
  @Input() selectSample: Sample | null;
  @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() createSampleFromResearch: EventEmitter<Sample> = new EventEmitter<Sample>();
  @Output() createSample: EventEmitter<{ sample: Sample, index: number }> = new EventEmitter<{ sample: Sample, index: number }>();

  constructor(
    private fb: FormBuilder,
    private sampleService: SampleService,
    private humansService: HumansService,
    private animalsService: AnimalsService,
    private dictionariesService: DictionariesService) {
  }

  protected readonly OrderTypes = OrderTypes;
  protected readonly GENDERS: Gender[] = GENDERS;
  protected readonly renderHumanName = renderHumanName;
  protected readonly renderParentName = renderParentName;

  private readonly destroy$ = new Subject<void>();

  private searchHumansSubject:Subject<string> = new Subject<string>();
  private searchAnimalsSubject:Subject<string> = new Subject<string>();
  private searchBiomatsSubject:Subject<string> = new Subject<string>();
  private searchOligonucleotidesSubject:Subject<string> = new Subject<string>();
  private searchDefOligonucleotidesSubject:Subject<string> = new Subject<string>();

  form: FormGroup;
  isOpen: boolean = true;
  loading: boolean = false;
  isOpenHumanModal: boolean = false;
  isOpenAnimalModal: boolean = false;
  
  human: Human | null;
  humans: Human[];

  animal: Animal | null;
  animals: Animal[];

  biomats: Biomat[];
  oligonucleotides: Oligonucleotide[];
  defOligonucleotides: Oligonucleotide[];

  selectedTypeId?: number

  filters: Filter = {
    limit: 10,
    isDeleted: false
  };

  ngOnInit(): void {
    this.initializeForm();
    this.setupFormSubscriptions();
    this.initializeDataStreams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  prevTypeId: number | null = null;

  private initializeForm(): void {
    this.form = this.fb.group({
      animal: [this.selectSample?.animal?.id ? {...this.selectSample?.animal, fullName: (this.selectSample?.animal ? renderParentName(this.selectSample.animal) : null)} : null],
      type: [this.selectSample?.biomat?.type ?? this.orderType, Validators.required],
      status: [this.selectSample?.status ?? null, Validators.required],
      // status: [this.selectSample?.status ?? defaultSampleStatus, Validators.required],
      biomat: [this.selectSample?.biomat, Validators.required],
      isStorage: [this.selectSample?.isStorage],
      drawDate: [convertFormat(this.selectSample?.drawDate), dateValidator()],
      admissionOfficeDate: [convertFormat(this.selectSample?.admissionOfficeDate), dateValidator()],
      admissionLabDate: [convertFormat(this.selectSample?.admissionLabDate), dateValidator()],
      shelfLife: [convertFormat(this.selectSample?.shelfLife), dateValidator()],
      number: [this.selectSample?.number ?? ((this.orderName && this.currentNumber) ? `${this.orderName}.${this.currentNumber}` : null) , Validators.required],

      human: [this.selectSample?.human?.id ? {...this.selectSample?.human, fullName: (this.selectSample?.human ? renderHumanName(this.selectSample.human) : null)} : null],
      oligonucleotide: [this.selectSample?.oligonucleotide],
      defOligonucleotide: [this.selectSample?.defOligonucleotide],

      gender: [this.selectSample?.human?.gender
        ? GENDERS.find((gender: Gender): boolean => gender.name === translateToRusGender(this.selectSample?.human?.gender ?? ''))
        : null],

      gender_readonly: [this.selectSample?.human?.gender ? (this.selectSample?.human?.gender === 'Female' ? 'Женский' : 'Мужской') : null],

      defGender: [this.selectSample?.defGender
        ? GENDERS.find((defGender: Gender): boolean => defGender.name === translateToRusGender(this.selectSample?.defGender ?? ''))
        : null],
      isDeleted: [this.selectSample?.isDeleted ?? false]
    });

    this.human = this.form.get('human')?.value;

    this.form.get('human')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((human) => {
      this.human = human
    })

    this.animal = this.form.get('animal')?.value;

    this.form.get('animal')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((animal) => {
      this.animal = animal
    })

    const typeControl = this.form.get('type');
    const humanControl = this.form.get('human');
    const animalControl = this.form.get('animal');
    const statusControl = this.form.get("status");
    const biomatControl = this.form.get('biomat');
    const genderControl = this.form.get('gender');
    const defGenderControl = this.form.get('defGender');
    const oligonucleotideControl = this.form.get('oligonucleotide');
    const defOligonucleotideControl = this.form.get('defOligonucleotide');

    typeControl?.value ? biomatControl?.enable() : biomatControl?.disable();
    
    if (typeControl?.value?.id !== OrderTypes.ANIMAL) {
      humanControl?.setValidators([Validators.required]);
      humanControl?.updateValueAndValidity();
    }

    typeControl?.valueChanges.pipe(
      startWith(typeControl.value),
      pairwise(),
      takeUntil(this.destroy$)
    ).subscribe(([prevType, currType]) => {
        humanControl?.clearValidators();
        animalControl?.clearValidators();
    
        currType ? biomatControl?.enable() : biomatControl?.disable();

        if (currType?.id !== OrderTypes.ANIMAL) {
          humanControl?.setValidators([Validators.required]);
          animalControl?.reset();
          this.resetControls([animalControl, biomatControl, genderControl, defGenderControl, oligonucleotideControl, defOligonucleotideControl]);
        } else if (currType?.id === OrderTypes.ANIMAL) {
          animalControl?.setValidators([Validators.required]);
          this.resetControls([humanControl, biomatControl, genderControl, defGenderControl, oligonucleotideControl, defOligonucleotideControl]);
        }

        if (prevType?.id !== currType?.id) {
          [ biomatControl,
            genderControl,
            defGenderControl,
            oligonucleotideControl,
            defOligonucleotideControl
          ].forEach(control => control?.reset());
          this.getBiomats();
        }

        const status = currType?.id === OrderTypes.ANIMAL ? defaultAnimalSampleStatus : defaultHumanAndScienceSampleStatus;

        statusControl?.setValue(status);
        statusControl?.updateValueAndValidity();
        humanControl?.updateValueAndValidity();
        animalControl?.updateValueAndValidity();
    
        this.prevTypeId = currType?.id;
    });

  

    humanControl?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.handleControlValue(value?.gender);
    });
  }

  private resetControls(controls: Array<AbstractControl | null>): void {
    controls.forEach(control => control?.reset());
  }


  handleControlValue(gender: string) {
    if (gender) {
      const translatedGender = translateToRusGender(gender);
      const selectedGender = GENDERS.find((gender: Gender) => gender.name === translatedGender);
      if (selectedGender) {
        this.form.get('gender')?.setValue(selectedGender);
      }
    } else {
      this.form.get('gender')?.setValue(null)
    }
  }

  private setupFormSubscriptions(): void {
    const searchSubjects = {
      'human': this.searchHumansSubject,
      'animal': this.searchAnimalsSubject,
      'biomats': this.searchBiomatsSubject,
      'oligonucleotides': this.searchOligonucleotidesSubject,
      'defOligonucleotides': this.searchDefOligonucleotidesSubject
    };
    Object.entries(searchSubjects).forEach(([key, subject]) => {
      subject.pipe(
        debounceTime(900),
        takeUntil(this.destroy$)
      ).subscribe(searchTerm => {
          this.loadDataByKey(key, searchTerm);
      });
    });
  }

  private initializeDataStreams(): void {
    this.getHumans();
    this.getAnimals();
    this.getOligonucleotides();
    this.getDefOligonucleotides();
    this.statuses = this.statuses ?? this.getSampleStatuses();
    if (this.form.get('type')) {
      this.getBiomats();
    }
  }

  private loadDataByKey(key: string, searchTerm: string): void {
    switch (key) {
      case 'human':
        this.humansService.get(searchTerm ? {...this.filters, search: searchTerm } : this.filters).subscribe((response: HumansResponse) => {
          const searchHumans = response.data.map((h: Human) => {
            h.fullName = renderHumanName(h);
            return h
          })
          this.humans = searchHumans
        });
        break;
      case 'animal':
        this.animalsService.get(searchTerm ? {...this.filters, search: searchTerm } : this.filters).subscribe((response: AnimalsResponse) => {
          const searchAnimals = response.data.map((a: Animal) => {
            a.fullName = renderParentName(a);
            return a
          })
          this.animals = searchAnimals
        });
        break;
      case 'biomats':
        this.dictionariesService.getBiomats(searchTerm ? {...this.filters, search: searchTerm, biomatTypeId: this.form.get('type')?.value?.id } : {...this.filters, biomatTypeId: this.form.get('type')?.value?.id}).subscribe((response: BiomatsResponse) => this.biomats = response.data);
        break;
      case 'oligonucleotides':
        this.dictionariesService.getOligonucleotides(searchTerm ? {...this.filters, search: searchTerm } : this.filters).subscribe((response: OligonucleotidesResponse) => this.oligonucleotides = response.data);
        break;
      case 'defOligonucleotides':
        this.dictionariesService.getOligonucleotides(searchTerm ? {...this.filters, search: searchTerm } : this.filters).subscribe((response: OligonucleotidesResponse) => this.defOligonucleotides = response.data);
        break;
      default:
        console.warn(`Неизвестный ключ поиска: ${key}`);
    }
  }

  onSearchHuman = (input: string) => this.searchHumansSubject.next(input);
  onSearchAnimal = (input: string) => this.searchAnimalsSubject.next(input);
  onSearchBiomats = (input: string) => this.searchBiomatsSubject.next(input);
  onSearchOligonucleotide = (input: string) => this.searchOligonucleotidesSubject.next(input);
  onSearchDefOligonucleotide = (input: string) => this.searchDefOligonucleotidesSubject.next(input);

  addOrEditHumanHandler = (isAdd: boolean): void => {
    this.human = isAdd ? null : this.human;
    this.isOpenHumanModal = true;
  };

  handleCloseHuman = (): void => {
    this.human =  this.form.get('human')?.value;
    this.isOpenHumanModal = false;
  };

  handleCreateHumanEvent(data: Human): void {
    const fullName = renderHumanName(data)
    this.form.get('human')?.setValue({...data, fullName});
  }


  addOrEditAnimalHandler = (isAdd: boolean): void => {
    this.animal = isAdd ? null : this.animal;
    this.isOpenAnimalModal = true;
  };

  handleCloseAnimal = (): void => {
    this.animal =  this.form.get('animal')?.value;
    this.isOpenAnimalModal = false;
  };

  handleCreateAnimalEvent(data: Animal): void {
    this.form.get('animal')?.setValue(data);
  }


  createGetItems = <T>(service: any, method: string, itemsProperty: keyof T, property?: any): void => {
    this.loading = true;
    service[method](property ? {...this.filters, [property?.name]: [property?.value]} : this.filters).subscribe({
      next: (response: any): void => {
        (this as Record<keyof T, any>)[itemsProperty] = Object.values(response.data ?? response);
        this.loading = false;
      },
      error: (): void => {
        this.loading = false;
      },
    });
  };

  getAnimals = (): void => {
    this.createGetItems<SampleComponent>(this.animalsService, 'get', 'animals');
  };

  getBiomats = (): void => {
    this.createGetItems<SampleComponent>(this.dictionariesService, 'getBiomats', 'biomats', {name: 'biomatTypeId', value: this.form.get('type')?.value?.id});
  };

  getHumans(): void {
    this.loading = true;
    this.humansService.get(this.filters).subscribe({
      next: (response: HumansResponse): void => {
        const newHuman = Object.values(response.data).map((h: Human) => {
          h.fullName = renderHumanName(h);
            return h
        })
          this.humans = Object.values(response.data)
          .map((h: Human) => {
            h.fullName = renderHumanName(h);
            return h
        })
        this.humans = Object.values(newHuman)
        this.loading = false;
      },
      error: (): void => {
        this.loading = false;
      }
    });
  }

  getSampleStatuses = (): void => {
    this.createGetItems<SampleComponent>(this.sampleService, 'getStatuses', 'statuses');
  };

  getOligonucleotides = (): void => {
    this.createGetItems<SampleComponent>(this.dictionariesService, 'getOligonucleotides', 'oligonucleotides');
  };

  getDefOligonucleotides = (): void => {
    this.createGetItems<SampleComponent>(this.dictionariesService, 'getOligonucleotides', 'defOligonucleotides');
  };

  handleCloseButton(): void {
    this.handleClose();
    this.selectSample = null;
    this.isOpen = false;
    this.closeButtonClicked.emit()
  }

  async handleSaveButton(): Promise<void> {
    const data: SampleInput = {
      number: this.form.value.number,
      drawDate: this.form.value?.drawDate?.length ? `${this.form.value.drawDate.split('.').reverse().join('-')}T00:00:00.000Z` : null,
      admissionOfficeDate: this.form.value?.admissionOfficeDate?.length ? `${this.form.value.admissionOfficeDate.split('.').reverse().join('-')}T00:00:00.000Z` : null,
      admissionLabDate: this.form.value?.admissionLabDate?.length ? `${this.form.value.admissionLabDate.split('.').reverse().join('-')}T00:00:00.000Z` : null,
      shelfLife: (this.form.value.shelfLife && this.form.value.isStorage) ? `${this.form.value.shelfLife.split('.').reverse().join('-')}T00:00:00.000Z` : undefined,
      isStorage: this.form.value?.isStorage ?? undefined,
      biomatId: this.form.value.biomat!.id,
      statusId: this.form.value.status!.id,
      animalId: this.form.value.animal?.id,
      oligonucleotideId: this.form.value.oligonucleotide?.id,
      humanId: this.form.value.human?.id,
      defGender: (this.form.value.defGender?.name && translateToEngGender(this.form.value.defGender.name)),
      gender: (this.form.value.gender && translateToEngGender(this.form.value.gender.name)),
      defOligonucleotideId: this.form.value.defOligonucleotide?.id,


      isDeleted: this.form.value.isDeleted,
      ...(this.selectSample ? {
        id: this.selectSample.id
      } : {}),
    };

    (this.selectSample 
      ? this.isCopy ? this.sampleService.create(data) : this.sampleService.update(data)
      : this.sampleService.create(data)).subscribe({
        next: (sample: Sample): void => {
          this.addOrUpdateItem ? this.addOrUpdateItem(this.samples, sample) : null;
          this.addOrUpdateSample ? this.addOrUpdateSample(sample) : null;
          this.createSample.emit({ sample, index: this.currentNumber - 1 });
          this.createSampleFromResearch.emit(sample);
          this.handleClose();
          this.closeButtonClicked.emit()
        },
        error: (): void => {
        }
    });
  }
}
